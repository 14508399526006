import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
    name: 'Faq',
    components: { HeaderTopDashboard },
    data: function () {
      return {
        PLATAFORMA_KEY: 'plataforma',
        SMS_KEY: 'sms',
        MAILING_KEY: 'mailing',
        VOICE_KEY: 'voice',
        tab: null,
      }
    },
    computed: {
        questions () {
            return [
                {
                    plataforma: [
                        {
                            answer: this.$t('¿Qué significa multicanal?'),
                            responses: [
                                this.$t('{whiteLabelName} es la única plataforma que permite realizar envíos SMS, Mailing y Voz') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué es una campaña Básica?'),
                            responses: [
                            this.$t('Una campaña básica es aquella que se realiza a través de un único canal (SMS, mailing y voz) y en un único envío') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué es una campaña automática?'),
                            responses: [
                                this.$t('Una campaña automática es aquella que se programa para que sea lanzada de forma automática respondiendo a algún evento') + '.',
                                this.$t('Por ejemplo, programar de forma automática envío de felicitación en el día del cumpleaños de cada suscriptor') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo puedo registrarme?'),
                            responses: [
                                this.$t('El registro es completamente gratuíto y sin compromiso').toString() + '. ' + this.$t('Solo tienes que pulsar sobre el botón "REGÍSTRATE GRATIS" y seguir los pasos indicados').toString() + '. ' + this.$t('Ir a registro') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo acceder al portal?'),
                            responses: [
                                this.$t('Para acceder a la plataforma debe dirigirse a nuestra página web').toString() + ': https://www.360nrs.com/' + '.' + this.$t('Una vez dentro, en la parte superior derecha, podrá encontrar la pestaña "REGÍSTRATE GRATIS" desde donde podrá crear su cuenta personal').toString() + '. ' + this.$t('Si ya dispone de una cuenta registrada, podrá acceder a través de la pestaña "Iniciar sesión", situada también en la parte superior derecha de la página') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué metodos de pago hay?'),
                            responses: [
                                this.$t('Puedes pagar por tarjeta de crédito o por transferencia').toString() + '. ' + this.$t('Si eliges tarjeta, el saldo aparecerá reflejado en tu cuenta de inmediato').toString() + '. ' + this.$t('Si eliges transferencia, lo tendrás disponible dentro de los próximos días hábiles') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo crear una nueva campaña?'),
                            responses: [
                                this.$t('Para crear una nueva campaña tienes que dirigirte al panel lateral izquierdo y entrar en la pestaña "Campañas"').toString() + '. ' + this.$t('Una vez ahí, deberás seleccionar el tipo de campaña que quieres lanzar (SMS, mailing y voz) y a continuación seguir los sencillos pasos que el sistema te indica') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo gestionar las campañas?'),
                            responses: [
                                this.$t('Para poder gestionar tus campañas, has de dirigirte al menú lateral izquierdo y entrar en la pestaña "Gestion de campañas"').toString() + '. ' + this.$t('Ahí tendrás disponible el listado de todas las campañas realizadas así como la posibilidad de gestionarlas en función de tus necesidades (duplicándolas, editando, consultando las estadísticas o eliminándolas)') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué son los reportes?'),
                            responses: [
                                this.$t('Los reportes son informes detallados que te permiten conocer de manera individual las acciones que han llevado a cabo los destinatarios de tu campaña').toString() + '. ' + this.$t('Para acceder a un reporte deberás entrar en la pestaña "Informes" del menú lateral izquierdo y seleccionar la fecha de envío, el canal y el nombre de la campaña en la que estás interesado') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué son los formularios?'),
                            responses: [
                                this.$t('Desde nuestra plataforma también ponemos a tu disposición la posibilidad de crear formularios en función de tus necesidades').toString() + '. ' + this.$t('Desde la pestaña "Formularios" del menú lateral izquierdo podrás crear tus propios formularios e incluirlos en tus comunicaciones') + '.',
                            ],
                        },
                        {
                            answer: this.$t('Tengo una incidencia'),
                            responses: [
                            this.$t('Si has detectado algún error o tienes algún problema, puedes enviarnos un ticket desde tu cuenta de usuario o indica que quieres hablar con un agente y nos pondremos en contacto contigo') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo subir una base de datos?'),
                            responses: [
                            this.$t('Una vez iniciada sesión, nos dirigimos a "Gestión de Contactos" - "Importar Contactos" y seleccionamos el archivo que queremos subir').toString() + '. ' + this.$t('Los formatos admitidos son: XLS, CSV y TXT').toString() + '. ' + this.$t('Seleccionaremos el tipo de separador utilizado, si la Base de Datos contiene Cabecera o no y por último debemos confirmar que tenemos el consentimiento de nuestros clientes') + '.',
                            ],
                        },
                    ],
                    sms: [
                        {
                            answer: this.$t('¿Qué es un SMS?'),
                            responses: [
                                this.$t('Es un mensaje de texto de máximo 160 caracteres que se envía entre teléfonos móviles') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué es un SMS Landing Page?'),
                            responses: [
                                this.$t('Una Landing Page es una web con contenido promocional a la que se accede a través de un enlace contenido en un SMS') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Dónde puedo consultar el precio para SMS?'),
                            responses: [
                                this.$t('Puedes consultar el precio de SMS por volumen en la sección "Mis tarifas" de nuestra web').toString() + '. ' + this.$t('También puedes solicitar tu presupuesto a medida o hablar con un agente de {whiteLabelName}') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Puedo tener saldo de prueba?'),
                            responses: [
                                this.$t('Para obtener tu saldo de prueba tienes que rellenar tu perfil fiscal').toString() + '. ' + this.$t('Una vez rellenado te asignaremos saldo sin compromiso').toString() + '. ' + this.$t('Este saldo no caduca y lo tendrás disponible hasta el momento en el que decidas gastarlo') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué son los 2Ways SMS?'),
                            responses: [
                                '- ' + this.$t('Mantén un diálogo 2way interactúa con tu audiencia') + '.',
                                '- ' + this.$t('Utiliza un número virtual y recibe las respuestas a los SMS enviados a tus clientes en tu buzón de entrada') + '.',
                                '- ' + this.$t('Recibe las contestaciones en tiempo real') + '.',
                                '- ' + this.$t('Habilita la autorespuesta a tus mensajes recibidos') + '.',
                                '- ' + this.$t('Exporta los mensajes recibidos a csv o pdf') + '.',
                                '- ' + this.$t('Integra los mensajes en tu software de gestión') + '.',
                                '- ' + this.$t('Solicita tu número y comprueba su potencia') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿A qué países puedo enviar SMS?'),
                            responses: [
                                this.$t('Tenemos conexión con más de 180 países').toString() + '. ' + this.$t('Si quieres enviar a algún país que no está en la lista habla con tu Account Manager o con el Equipo de Soporte y configuraremos la ruta en tu cuenta de cliente') + ':',
                                '1.' + this.$t('Perfil de Facturación') + '.',
                                '2.' + this.$t('Saldo en tu cuenta') + '.',
                                '3.' + this.$t('Una Base de Datos con los Contactos en formato CSV, TXT o XLS') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué es un SMS Certificado?'),
                            responses: [
                                this.$t('El SMS Certificado es un mensaje de texto enviado a un teléfono móvil y que, a su vez, genera un certificado firmado digitalmente que tiene validez como prueba') + '.',
                                this.$t('En dicho documento se certifica de forma fehaciente el contenido del mensaje así como la fecha en la que se ha enviado y el estado del envío (si finalmente ha podido ser entregado o no a su destinatario)') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué significa el estado ACCEPTED?'),
                            responses: [
                                this.$t('Este estado significa que tu mensaje ha llegado a nuestro servidor') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué significa el estado DELIVERED?'),
                            responses: [
                                this.$t('Este estado significa que tu mensaje se ha entregado al terminal') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué significa el estado UNDELIVERED?'),
                            responses: [
                                this.$t('Este estado indica que tu sms no se ha entregado porque el cliente ha cancelado la suscripción o tiene la bandeja de entrada llena') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué significa el estado REJECTED?'),
                            responses: [
                                this.$t('Este estado indica que tu mensaje se ha rechazado por no ser un número de teléfono válido') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué significa el estado EXPIRED?'),
                            responses: [
                                this.$t('Este estado indica que tu mensaje ha caducado por haberse intentado entregar durante 72h pero el número no se encontraba operativo durante este periodo') + '.',
                            ],
                        },
                    ],
                    mailing: [
                        {
                            answer: this.$t('¿Garantizáis la entrega de mi campaña de email?'),
                            responses: [
                                this.$t('{whiteLabelName} garantiza la entrega de tus campañas de mailing gracias a un concienzudo sistema de reputación de IP y a la posibilidad de tener una cuenta con IP dedicada, que permite a cada usuario de la plataforma el control de su propia reputación').toString() + '. ' + this.$t('Además, un infranqueable control anti-spam y la colaboración con los principales entes certificadores aseguran que tus campañas lleguen a donde tienen que llegar: directamente a la bandeja de entrada de tus receptores') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Puedo crear una plantilla corporativa sin tener un equipo de diseño?'),
                            responses: [
                                this.$t('Sí, contamos con un diseñador de plantillas intuitivo HTML mobile first 100% personalizable, con el que podrás crear tus mejores campañas de email con un sistema Drag & Drop') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo mejorar los resultados de las campañas mailing?'),
                            responses: [
                                this.$t('Para obtener buenos resultados en el mailing es conveniente leer el libro blanco de mailing que se encuentra en el apartado "Ayuda" - "Libro Blanco de Mailing"') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué hago con los hard bounces?'),
                            responses: [
                                this.$t('Si obtenemos hard bounces en una campaña, desde el apartado "estadísticas" tenemos la opción de añadir directamente los hard bounce a la lista negra de mailing').toString() + '. ' + this.$t('También podemos hacerlo de forma individual, accediendo a la ficha del contacto y marcando la opción "añadir a lista negra de mailing"') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo puedo filtrar contactos?'),
                            responses: [
                                this.$t('Para filtrar contactos nos dirigimos al panel izquierdo, "Base de datos" - "Contactos"').toString() + '. ' + this.$t('En la parte superior tendrás la opción de filtrar según distintas condiciones') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Puedo personalizar los emails?'),
                            responses: [
                                this.$t('Crea campos personalizados en tu Base de Datos e incluyelos en tu plantilla').toString() + '. ' + this.$t('Personaliza sin límites el contenido de tus campañas Mailing y habla a tus clientes de tú a tú para lograr una relación más profunda y duradera') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Las plantillas de email están optimizadas para dispositivos móviles?'),
                            responses: [
                                this.$t('La plataforma 360NRS está diseñada para proporcionar una experiencia única al usuario final bajo el concepto "mobile first", por lo que las plantillas diseñadas con el editor nativo de la plataforma garantizan la legibilidad total desde cualquier dispositivo móvil') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Puedo automatizar mis campañas de email?'),
                            responses: [
                                this.$t('Te damos la posibilidad de programar campañas automáticas y campañas trigger, con las que podrás crear autorespuestas antes determinadas acciones de tu público, o bien automatizar campañas que se enviarán cuando determinados hitos se alcancen en tu base de datos').toString() + '. ' + this.$t('Gracias a {whiteLabelName} no pasarás por alto ni una sola oportunidad de fidelizar a tus clientes') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué estadísticas puedo obtener en mi campaña de email?'),
                            responses: [
                                this.$t('Enviados, entregados, abiertos, clics por botón o enlace, hard bounces, soft bounces, bajas y costes') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cómo gestiono las bajas de mi campaña?'),
                            responses: [
                                this.$t('Las bajas se gestionan automáticamente a través de nuestra plataforma, por lo que no te tendrás que preocupar de añadir contactos a una lista negra') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué es una Newsletter?'),
                            responses: [
                                this.$t('Son notificaciones que se distribuyen de forma regular, con el fin de proporcionar información de interés acerca de promociones, ofertas, etc') + '.',
                            ],
                        },
                    ],
                    voice: [
                        {
                            answer: this.$t('¿Qué son las llamadas de voz automáticas?'),
                            responses: [
                                this.$t('Son llamadas masivas que puedes realizar de forma automáticas a toda tu base de datos') + ':',
                                '1.' + this.$t('Válido para móviles y fijos') + '.',
                                '2.' + this.$t('Textos a voz automáticos para llamadas personalizadas multidioma') + '.',
                                '3.' + this.$t('Posibilidad de subir tus propias cuñas publicitarias en múltiples formatos') + '.',
                                '4.' + this.$t('Comunicación masiva en un formato sorprendente para el usuario') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Puedo subir mi propio audio?'),
                            responses: [
                                this.$t('Podrás subir tu propio audio pregrabado con cuñas de radio, recomendaciones de influencers, mensajes divertidos').toString() + '... ' + this.$t('o utilizar una de nuestras más de 40 voces humanas en más de 20 idiomas para transformar el texto en voz') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Cuántas interacciones con el teclado puedo programar en mi campaña de voz?'),
                            responses: [
                                this.$t('Puedes programar diferentes acciones como: Añadir a Blacklist, reproducir otro mensaje, enviar un SMS, Transferir, repetir el mensaje o añadir un nuevo submenú') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Qué sucede en caso de que el receptor no pulse una tecla no programada?'),
                            responses: [
                                this.$t('En tu campaña siempre deberás de incluir un mensaje de error u omisión').toString() + '. ' + this.$t('Es decir, un audio (subir tu propio audio o transformar el texto a audio) para una selección "No válida"') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Puedo programar las llamadas en un horario concreto?'),
                            responses: [
                                this.$t('Si, en nuestros campos avanzados encontrarás "Horario permitido" para indicar entre qué franja horaria quieres que enviemos las llamadas') + '.',
                            ],
                        },
                        {
                            answer: this.$t('¿Puedo realizar una llamada de prueba?'),
                            responses: [
                                this.$t('Antes de enviar la campaña te damos la posibilidad de ver el resultado final a través de una llamada de prueba') + '.',
                            ],
                        },
                    ],
                },
            ]
        },
    },
}
